import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { ReviewRejectionInformationResponse } from '@/types/release-plan/release-plan-response.type';
import type { LatestRejectionInfoResponse } from '@/types/review/review-response.type';
import { camelToSnake } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924186
export const fetchLatestRejectionInfoApi = async (params?: {
  productNo: number;
  verifyType: string;
  target: string;
}): Promise<LatestRejectionInfoResponse | undefined> => {
  const { data } = await useRequest<LatestRejectionInfoResponse>(
    `${apiUrl}/attempt-verify/reject`,
    {
      params: camelToSnake(params),
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924181
export const createReviewRequestApi = async (request?: {
  productNo: number;
  verifyType: string;
  target: string;
  // data?: {
  //   autoReleaseYn?: string;
  // };
}): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/attempt-verify`, {
    method: ApiMethod.Post,
    showCommonError: false,
    data: camelToSnake(request)
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924183
export const cancelReviewRequestApi = async (request?: {
  productNo: number;
  verifyType: string;
  target: string;
}): Promise<ReviewRejectionInformationResponse | undefined> => {
  const { data } = await useRequest<ReviewRejectionInformationResponse>(
    `${apiUrl}/attempt-verify/cancel`,
    {
      method: ApiMethod.Post,
      showCommonError: false,
      data: camelToSnake(request)
    }
  );

  return data;
};
